import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Stories.module.scss"
import { Link } from 'gatsby';


export default function Stories({ strapiData }) {
  const data = [
    {
      name: "Product Engineering",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Product_Engineering_2_ce9958d7fb.svg",
      description:
        "Our Product Engineering Services cover product management, design, development, DevOps, testing, security & more.",
      button: "/product-engineering/",
      skills: [
        {
          serName: "Discovery Workshop",
          serLink: "/discovery-workshop/",
        },
        {
          serName: "Software Quality Assurance",
          serLink: "/software-quality-assurance/",
        },
        {
          serName: "Custom Software Development",
          serLink: "/custom-software-development/",
        },
        {
          serName: "Web App Development",
          serLink: "/web-application-development-services/",
        },
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_40_8931b24ca8.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_41_7b3f5b7723.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_38_ad6be92aff.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_33_1026564ab7.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_39_36b5c7d840.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_36_36a3ee84aa.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_37_7df7e0a59d.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_30_253585b67f.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_31_e4f27a7e0f.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_34_2881741607.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_35_a9dbb87100.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_32_25f5737661.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_29_55cf7869a7.svg",
        },
      ],
    },
    {
      name: "Data & Artificial Intelligence",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_d7ddbab7d6.svg",
      description:
        "We drive your business forward by crafting tomorrow's AI solutions today, ensuring they are futuristic enough to align with your unique goals and challenges.",
      button: "/ai-development-services/",
      skills: [
        {
          serName: "AI Strategy And Consulting",
          serLink: "/contact-us/",
        },
        {
          serName: "ML Model Deployment & Training ",
          serLink: "/contact-us/",
        },
        {
          serName: "Natural Language Processing",
          serLink: "/contact-us/",
        },
        {
          serName: "Custom Computer Vision Solutions",
          serLink: "/contact-us/",
        },
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_30_aea04f17f7.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_73_36e0f4bcaa.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_61_3ebe3547d0.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_74_643008704e.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_45_60d1c401d0.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_49_90bab0403f.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_41_9c1e40cf7a.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_40_2c92b87f03.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_37_966606d3fd.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_18_1_6d087bf3ec.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_34_ea54698b21.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_26_3511327a4d.svg",
        },
      ],
    },
    {
      name: "Hire Dev",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/chat_3_1_3472292811.svg",
      description:
        "Invozone helps you hire, acquire and retain top development talent. Boost your in-house team or build your own dev center.",
      button: "/hire-remote-developers/",
      skills: [
        {
          serName: "Staff Augmentation",
          serLink: "/staff-augmentation-company/",
        },
        {
          serName: "Dedicated Teams",
          serLink: "/dedicated-development-team/",
        },
        {
          serName: "Hire By Role",
          serLink: "/hire-remote-developers/",
        },
        {
          serName: "Hire By Technology",
          serLink: "/hire-remote-developers/",
        },
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_50_70740a5e25.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_36_8d6147d209.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_53_db4fda4061.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_43_d197a270c7.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_48_35bb95e47f.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_35_9cb6678541.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_54_831082be1a.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_65_1b0daa77b7.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_38_197010c786.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_56_908a883815.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_24_b5ebf1af69.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_63_59f239bab4.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_23_77bdfbbb15.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_31_96069822a8.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_17_182ce8f06c.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_16_5e595068cb.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_22_d92bf6792a.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_12_a248ccabe0.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_6_d046f4e688.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_5_01dad54bbc.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_4_6734c4c4ca.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_2_d3e80fdb18.svg",
        },
      ],
    },
    {
      name: "DevOps & CloudOps",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/cloud_computing_5_1_b0cc059ea8.svg",
      description:
        "We optimize operations with advanced DevOps and CloudOps strategies while boosting agility & scalability in technology deployments.",
      button: "/devops/",
      skills: [
        {
          serName: "CI/CD As a Service",
          serLink: "/contact-us/",
        },
        {
          serName: "Infrastructure As Code",
          serLink: "/contact-us/",
        },
        {
          serName: "Security And Compliance",
          serLink: "/contact-us/",
        },
        {
          serName: "Cloud Migration",
          serLink: "/contact-us/",
        },
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_62_e1cbc23a10.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_28_1fe21129b6.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_19_401e00bde5.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_47_379d9f3909.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_15_aefb1c427f.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_10_6d36418948.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_42_cb21bf8883.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_9_9478a75ebb.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_7_a310d12f9b.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_1_fa917b826a.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_3_4ded4b8c84.svg",
        },
      ],
    },
    {
      name: "Emerging Technologies",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007569_277470ef3c.svg",
      description:
        "We’re dedicated to the latest IT trends, integrating immersive experiences, augmented reality, and virtual reality to provide you with reliable and innovative solutions.",
      button: "/contact-us/",
      skills: [
        {
          serName: "Metaverse",
          serLink: "/contact-us/",
        },
        {
          serName: "AR/VR",
          serLink: "/contact-us/",
        },
        {
          serName: "Apple Vision Pro",
          serLink: "/contact-us/",
        },
        {
          serName: "Cyber Security",
          serLink: "/cyber-security-services/",
        },
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_27_0eebfe82ba.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_28_6938e335e4.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_25_3c40ae76e1.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_24_fcfc0b60e2.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_26_859cd8e18c.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_23_d223ce599b.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_22_073d002a13.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_21_72ea731459.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_20_451dbca0d0.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_19_20eeb598d8.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_18_8f08ce0cb0.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_16_ac1243f4da.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_17_8f9d27731f.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_14_4a9d7b3d9e.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_15_27c9ac859c.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_13_21ed4f0750.svg",
        },
      ],
    },
    {
      name: "Blockchain & Web 3.0",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/blockchain_2_1_37e24ad7c8.svg",
      description:
        "We provide innovative solutions in blockchain technology, developing secure and decentralized applications, enhancing digital trust, and improving operational efficiency.",
      button: "/blockchain-development-services/",
      skills: [
        {
          serName: "DApp Development",
          serLink: "/contact-us/",
        },
        {
          serName: "Smart Contract Deployment",
          serLink: "/contact-us/",
        },
        {
          serName: "Blockchain Consulting",
          serLink: "/contact-us/",
        },
        {
          serName: "Tokenization Solutions",
          serLink: "/contact-us/",
        },
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_12_20f13ffb94.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_11_42cd8ddb38.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_6_9e2fe3a923.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_6c9d3aed63.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_9_69ad51121f.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_10_9d664a2142.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_3_aca79b2d9a.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_4_5e14b87d81.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_7_9e6175cfdd.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_8_081b818e1c.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_5_85bd039db7.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_1_ba60e0413c.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_2_e0afb6da7b.svg",
        },
      ],
    },
  ]

  const [activeStory, setActiveStory] = useState(data[0])

  const handleVideoIconClick = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <div className={styles.section}>
        <Container>
          <div className={styles.headSearch}>
            <h2 className={styles.heading}>
              Software Development Services <span>Forward Planners</span>
            </h2>
            <div className={styles.talkBtn}>
              <Link to={"/software-development-services"} className="newBtn">
                View All
              </Link>
            </div>
          </div>
          <Row className="gap-50">
            <Col xs={12} lg={5} className={styles.storyCardWrapper}>
              <Row className="gap-19 justify-content-center">
                {data?.map(el => (
                  <Col
                    xl={4}
                    lg={6}
                    md={6}
                    className={`${styles.rowMarging} gap-30`}
                    key={`story_${el.name}`}
                  >
                    <div
                      className={`${styles.storyCard} ${
                        el.name === activeStory.name && styles.active
                      }`}
                      onClick={() => setActiveStory(el)}
                    >
                      <div
                        className={`${styles.imgContainer} d-flex justify-content-center align-items-center`}
                      >
                        <img
                          src={el?.avatar}
                          alt={el?.name}
                          decoding="async"
                          loading="lazy"
                          style={{
                            width: "40px",
                            height: "40px",
                          }}
                          className={`${styles.serImg} img-fluid`}
                        />
                      </div>
                      <div className={`${styles.infoContainer} text-center`}>
                        <p>{el?.name}</p>
                        <p className={styles.role}>{el?.role}</p>
                      </div>
                    </div>
                    {el.name === activeStory.name && (
                      <div
                        className={`${styles.columnTopBannerImg}  d-sm-block d-md-none`}
                      >
                        {data?.map(el => (
                          <div>
                            {el.name === activeStory.name && (
                              <div className={styles.subHeading}>
                                <p className={styles.plannerSub}>{el?.name}</p>
                                <p className={styles.dec}>{el?.description}</p>
                                <div className={styles.points}>
                                  {el.skills &&
                                    el.skills.map((skill, eIdx) => (
                                      <div
                                        className={styles.arrowPoint}
                                        key={`skill_${eIdx}`}
                                      >
                                        <img
                                          src="https://invozone-backend.s3.us-east-1.amazonaws.com/Subtract_caa16ad879.png"
                                          decoding="async"
                                          loading="lazy"
                                          alt="arrow"
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        />
                                        {typeof skill === "object" &&
                                        skill.serName &&
                                        skill.serLink ? (
                                          <Link to={skill.serLink}>
                                            <p className={`${styles.skill}`}>
                                              {skill.serName}
                                            </p>
                                          </Link>
                                        ) : (
                                          <p className={`${styles.skill}`}>
                                            {skill}
                                          </p>
                                        )}
                                      </div>
                                    ))}
                                </div>

                                <div>
                                  {el.productImages &&
                                    el?.productImages.map((prodImg, ind) => (
                                      <img
                                        src={prodImg?.img}
                                        className={styles.prodImg}
                                        decoding="async"
                                        loading="lazy"
                                        alt="services"
                                        style={{
                                          height: "31px",
                                          width: "31px",
                                        }}
                                      />
                                    ))}
                                </div>
                                <div className="pb-4">
                                  <Link
                                    to={el?.button}
                                    className="newBtn mt-3 mb-5"
                                  >
                                    View More
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </Col>
                ))}
                <div className={styles.talkBtn2}>
                  <Link
                    to={"/software-development-services"}
                    className="newBtn"
                  >
                    View All
                  </Link>
                </div>
              </Row>
            </Col>
            <Col xs={12} lg={{ span: 6, offset: 1 }}>
              <div
                className={`${styles.columnTopBannerImg2} d-md-block d-sm-none `}
              >
                {data?.map(el => (
                  <div>
                    {el.name === activeStory.name && (
                      <div className={styles.subHeading}>
                        <h3 className={styles.subHeading2}>{el?.name}</h3>
                        <p className={styles.dec}>{el?.description}</p>
                        <div className={styles.points}>
                          {el.skills &&
                            el.skills.map((skill, eIdx) => (
                              <div
                                className={styles.arrowPoint}
                                key={`skill_${eIdx}`}
                              >
                                <img
                                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Subtract_caa16ad879.png"
                                  decoding="async"
                                  loading="lazy"
                                  alt="arrow"
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                  }}
                                />
                                {typeof skill === "object" &&
                                skill.serName &&
                                skill.serLink ? (
                                  <Link to={skill.serLink}>
                                    <p className={`${styles.skill}`}>
                                      {skill.serName}
                                    </p>
                                  </Link>
                                ) : (
                                  <p className={`${styles.skill}`}>{skill}</p>
                                )}
                              </div>
                            ))}
                        </div>
                        {el.productImages &&
                          el?.productImages.map((prodImg, ind) => (
                            <div className={styles.prodImg}>
                              <img
                                src={prodImg?.img}
                                decoding="async"
                                loading="lazy"
                                alt="services"
                                style={{ height: "31px", width: "31px" }}
                              />
                            </div>
                          ))}
                        <div style={{ marginTop: "24px" }}>
                          <Link to={el?.button} className="newBtn mt-5">
                            View More
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
